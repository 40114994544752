.checkbox {
  $parent: &;
  margin-bottom: 0;
  margin-right: 0;

  &__input {
    svg {
      fill: var(--blue-1) !important;
    }
  }
  &__label {
    font-size: 16px;
    &--bold {
      font-weight: bold;
    }
  }
  &--disabled {
    #{$parent}__input {
      svg {
        fill: var(--grey-1) !important;
      }
    }
    #{$parent}__label {
      color: var(--black) !important;
      font-weight: normal;
    }
  }
}
