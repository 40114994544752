.modal-root {
  overflow: auto;
}

.paper__width {
  width: 350px;
  min-height: 300px;
  overflow: visible;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 14px 12px 8px 12px;
  line-height: 30px;
  &__title {
    font-weight: 400;
    color: var(--blue-2);
  }

  &__close {
    position: relative;
    top: -6px;

    svg {
      fill: var(--blue-1);
    }
  }
}

.modal-actions {
  padding: 0px 12px 16px 12px;
  background: var(--white);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.customized-dialog-content {
  overflow: visible;
  padding: 12px;
  background: var(--white);
  min-height: 182px;
  .text-field,
  .autocomplete-field {
    margin-bottom: 10px;
  }
}
