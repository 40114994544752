.datepicker-months {
  &__year {
    margin-bottom: 10px;
    border-top: 1px solid var(--grey-1);
    padding: 10px 0 0 13px;
    margin-top: 8px;
  }
  &__month-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  &__month {
    height: 44px;
    width: calc(100% / 4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__month-btn {
    display: block;
    height: 36px;
    width: 64px;
    min-width: auto;
    border-radius: 100px;
    border: 0;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    outline: none;
    color: var(--black);
    text-transform: capitalize;
    &--curr-month {
      background-color: var(--white);
      border: 1px solid var(--grey-1);
      &:hover {
        border: 1px solid var(--grey-1);
      }
    }
    &--selected-month {
      background-color: var(--blue-3);
      border: 1px solid var(--blue-3);
    }
    &:hover {
      background-color: var(--grey-1);
    }
  }
}
