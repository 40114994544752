.department {
  &__org-actions {
    margin-bottom: 24px;
    display: flex;
  }
  &__org-actions-left {
    display: flex;
    width: 50%;
    justify-content: flex-start;
  }
  &__org-actions-right {
    display: flex;
    width: 50%;
    justify-content: flex-end;
  }
  &__add-new-btn {
    background-color: var(--blue-1);
    &:hover {
      background-color: var(--blue-1);
    }
  }
  &__active {
    background-color: #c9d2d7;
    &:hover {
      background-color: var(--white);
    }
  }
  &__info-btn {
    margin-left: 8px;
  }
}
