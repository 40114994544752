.tree-toggle-buttons {
  position: absolute;
  top: 55px;
  left: 10px;
  &__link {
    color: var(--blue-1);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: var(--blue-2);
    }
    &:visited {
      color: var(--blue-1);
    }
    &:first-child {
      margin-right: 15px;
    }
  }
}
