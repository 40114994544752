.drop-down-menu-item {
  padding: 0 12px;
  height: 3rem;
  line-height: 3rem;
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-1);
  }
  &:hover,
  &--selected {
    background-color: var(--blue-3) !important;
    color: var(--blue-2);
    svg {
      fill: var(--blue-2);
    }
  }
  &--selected {
    color: var(--black);
  }
}
