.autocomplete {
  &__input {
    flex: 1;
    display: flex;
    padding: 0;
  }

  &__value {
    flex: 1;
    display: flex;
    padding: 0 0 3px;

    &:first-child {
      color: var(--black);
    }
  }

  &__indicator {
    opacity: 0.6;
  }
}

.autocomplete-field {
  
  &__label {
    color: var(--grey-2);

    &--error {
      color: var(--red-1) !important;
    }

    &--focused {
      color: var(--blue-1) !important;
    }

    &--filled {
      color: var(--black);
      transform: translate(0, 0);
    }
    &--shrinked {
      transform: scale(1);
      color: var(--black);
    }
  }

  &--disabled {
    cursor: not-allowed !important;
  }

  &__underline {
    &::before {
      border-bottom: 1px solid var(--blue-1);
    }
    &::after {
      border-color: var(--blue-1);
    }
    &:hover::before {
      border-bottom: 2px solid var(--blue-1) !important;
    }

    &--disabled {
      &::before {
        border-bottom: 1px solid var(--grey-3);
      }
      &::after {
        border-color: var(--grey-3);
      }
      &:hover::before {
        border-bottom: 1px solid var(--grey-3);
      }
    }

    &--error {
      &::before {
        border-bottom: 1px solid var(--red-1) !important;
      }
      &::after {
        border-color: var(--red-1) !important;
      }
      &:hover::before {
        border-bottom: 1px solid var(--red-1) !important;
      }
    }
  }
  &__menu {
    border-radius: 3px !important;
    overflow: hidden;
    border: 1px solid var(--grey-3);
    box-shadow: none; 
  }
  &__menu-list {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    max-height: 341px !important;
  }
  &__option {
    padding: 16px 12px;
    font-size: 16px;
    line-height: 24px;
    border: 0;
    background-color: var(--white) !important;
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-1);
    }
    &:hover {
      color: var(--blue-2);
      background-color: var(--blue-3) !important;
    }
    &--selected {
      color: var(--black) !important;
    }
  }
  &__helper {
    font-size: 16px;
    &--error {
      color: var(--red-1) !important;
    }
  }
}
