.add-new-item {
  height: auto;

  &__button,
  &__button:hover {
    background: var(--grey-1);
    color: var(--blue-1);
  }

  &__icon {
    fill: var(--blue-1);
  }

  &__team-id {
    margin-top: 0.8rem;
  }

  &__error-message {
    color: var(--red-1);
  }
  &__required-message {
    margin: 32px 0 16px 0;
  }
}
