.icon {
  transition: transform 0.3s ease-in;
  &--rotate-right {
    transform: rotate(90deg);
  }
  &--rotate-bottom {
    transform: rotate(180deg);
  }
  &--rotate-left {
    transform: rotate(270deg);
  }
}
