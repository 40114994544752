.staff-profile {
  $p: &;
  &__panel-buttons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1em;
    padding-top: 30px;
    button {
      margin-left: 8px;
    }
  }
  &__hidden-panel-field {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  &__edit-button-wrap {
    margin-bottom: 24px;
    display: flex;
  }
  &__external-btn {
    &:hover {
      #{$p}__external-icon {
        fill: var(--blue-2);
      }
    }
  }
  &__external-icon {
    fill: var(--blue-1);
    margin-left: 5px;
    :hover > & {
      fill: var(--blue-2);
    }
  }
  &__external-text {
    margin-bottom: 12px;
  }
}
