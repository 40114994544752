.drop-zone {
  &__container {
    display: flex;
    border: 1px dashed var(--blue-1);
    color: var(--blue-1);
    border-radius: 3px;
    padding: 20px 12px;
    cursor: pointer;
    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--blue-2);
      border-color: var(--blue-2);
      outline: none;
    }
  }

  &--is-active {
    background-color: var(--blue-3);
  }
  &__icon {
    fill: var(--blue-1);
    margin-right: 10px;
    &--upload-icon {
      :hover > & {
        fill: var(--blue-2);
      }
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__list-item {
    display: flex;
    align-items: center;
    color: var(--blue-1);
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &__file-name {
    flex: 1;
  }
  &__label {
    cursor: pointer;
  }
}
