.table {
  $p: &;
  width: 100% !important;
  &__head-row {
    background-color: var(--white);
  }
  &__row {
    &:hover {
      background-color: #f8f8f8;
      #{$p}__row-cell--fixed {
        background-color: #f8f8f8;
      }
    }
  }
  &__head-cell,
  &__row-cell {
    font-family: var(--primary-font) !important;
    font-size: 16px !important;
    border-bottom: 1px solid var(--grey-1) !important;
  }
  &__head-cell {
    padding: 0 !important;
    border-bottom: 1px solid var(--black) !important;
    &:hover {
      #{$p}__sort-icon {
        opacity: 1;
        fill: var(--blue-1);
        background-color: var(--white);
        &--is-active {
          opacity: 1;
          background-color: var(--blue-3);
          fill: var(--blue-2);
        }
      }
    }
  }
  &__row-cell {
    padding: 12px 8px !important;
  }
  &__row-cell,
  &__head-cell {
    &--fixed {
      position: sticky;
      left: 0;
      background-color: var(--white);
      z-index: 1;

      &:after {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--grey-1);
      }
    }
  }
  &__head-cell-content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: bold;
    padding: 12px 8px;
    cursor: pointer;
  }
  &__sort-icon {
    fill: var(--black);
    opacity: 0;
    transition: transform 0.1s;
    background-color: var(--blue-3);
    margin-left: 8px;
    border-radius: 3px;
    &--is-active {
      opacity: 1;
    }
  }
}

.table-wrap {
  margin-top: 24px;
  overflow: auto;
  position: relative;
  z-index: 1;
  &--is-scrolled {
    background-color: var(--white);
  }
}
