.avatar {
  margin-top: 10px;
  display: flex;
  &__image {
    width: 170px;
    height: 230px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__preview-icon {
    width: 200px;
    height: auto;
    position: relative;
    left: -33px;
    fill: var(--grey-3);
  }
}
