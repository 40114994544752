.button {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: none;
  text-decoration: none;
  font-size: 16px;
  line-height: 16px;
  border-radius: 3px;
  min-width: 48px;
  min-height: 44px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  &--primary {
    border-color: var(--blue-1);
    background-color: var(--blue-1);
    color: var(--white);
    &:hover {
      border-color: var(--blue-4);
      background-color: var(--blue-4);
    }
  }
  &--secondary {
    border-color: var(--blue-3);
    background-color: var(--white);
    color: var(--blue-1);
    &:hover {
      color: var(--blue-2);
      background-color: var(--blue-3);
    }
  }
  &--success {
    border-color: var(--green-1);
    background-color: var(--green-1);
    color: var(--white);
    &:hover {
      background-color: var(--green-2);
      border-color: var(--green-2);
    }
  }
  &--warning {
    border-color: var(--yellow-1);
    background-color: var(--yellow-1);
    color: var(--black);
    &:hover {
      border-color: var(--yellow-2);
      background-color: var(--yellow-2);
    }
  }
  &--danger {
    border-color: var(--red-1);
    background-color: var(--red-1);
    color: var(--white);
    &:hover {
      border-color: var(--red-2);
      background-color: var(--red-2);
    }
  }
  &--disabled {
    border-color: var(--grey-3);
    background-color: var(--white);
    color: var(--grey-2);
    &:hover {
      border-color: var(--grey-3);
      background-color: var(--white);
      color: var(--grey-2);
    }
  }
  &--link {
    display: inline-block;
    &:hover {
      text-decoration: none;
    }
  }
}
