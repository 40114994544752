.ak-spinner {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
  }

  &--cover-page {
    width: 100vw;
    height: 100vh;
  }

  &__body {
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -50px;

    &--cover-page {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -50px;
      margin-top: -50px;
    }
    &--is-centered {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -50px;
      margin-top: -50px;
    }
  }

  &__svg {
    color: var(--blue-1) !important;
  }
  &__circle {
    stroke-width: 4px;
  }
}
