.department-item-card {
  display: block;
  height: 100%;

  &__title {
    font-size: 1.25rem;
    color: var(--blue-1);
    margin-bottom: 20px;

    &-text:hover {
      cursor: pointer;
      color: var(--blue-2);
      transition: color 0.3s;
      text-decoration: underline;
    }
  }

  &__title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }

  &__content {
    margin-bottom: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__content-small {
    font-size: 0.9rem;
  }

  &__action {
    fill: var(--blue-1);
    cursor: pointer;
  }

  &__action--disabled {
    fill: var(--grey-3);
  }

  &__actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__action {
    &--disabled {
      color: var(--grey-3);
    }
    &__btn {
      &--disabled {
        pointer-events: auto !important;
        cursor: not-allowed !important;
      }
      &--disabled:hover {
        background: none;
      }
    }
  }
}
