.drop-down-with-categories {
  &__select:before {
    border-bottom-color: var(--blue-1);
  }
  &__select:hover:before {
    border-bottom-color: var(--blue-1) !important;
  }
  &__select:after {
    border-bottom-color: var(--blue-1) !important;
  }
  &__input {
    color: var(--black);
  }
  &__label {
    &--focused,
    &--shrink {
      transform: scale(1);
      top: 3px;
    }
    &--focused {
      color: var(--blue-1) !important;
    }
    &--shrink {
      color: var(--black);
    }
  }

  &--error {
    .drop-down-with-categories {
      &__label {
        color: var(--red-1) !important;
      }
      &__select:hover:before {
        border-bottom-color: var(--red-1) !important;
      }
      &__select:after {
        border-bottom-color: var(--red-1) !important;
      }
    }
  }
  &__paper {
    border-radius: 3px;
    background: var(--white);
  }
  &__list {
    padding: 0;
  }

  &__menu-item {
    padding: 16px 12px;
    font-size: 16px;
    line-height: 24px;
    border: 0;
    background-color: var(--white) !important;
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-1);
    }
    &:hover {
      color: var(--blue-2);
      background-color: var(--blue-3) !important;
    }
    &--selected {
      background-color: var(--blue-3) !important;
      color: var(--black) !important;
    }
  }
  &__helper {
    font-size: 16px;
    &--error {
      color: var(--red-1) !important;
    }
  }
  &__custom {
    width: 30%  !important;
  }
 }
