@import '../../../App/media';
.sidebar {
  &__paper {
    width: 100%;
    @include for-size(md) {
      width: calc(var(--base-size) * 50);
    }
  }
  &__close {
    padding-left: var(--space-medium);
    padding-right: var(--space-medium);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(var(--base-size) * 8);
    border-bottom: 1px solid var(--grey-1);
  }
  .icon-button {
    width: calc(var(--base-size) * 6);
    height: calc(var(--base-size) * 6);
  }
  &__app-name {
    color: var(--red-1);
    text-decoration: none;
    @include for-size(md) {
      font-size: 20px;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__link {
    display: block;
    height: calc(var(--base-size) * 7);
    line-height: calc(var(--base-size) * 7);
    padding: 0 calc(var(--base-size) * 6);
    text-decoration: none;
    color: var(--black);
    border-bottom: 1px solid var(--grey-1);
    position: relative;
    &:hover {
      text-decoration: none;
    }
  }

  &__link:hover,
  &__link:active,
  &__link--active,
  &__link--active:hover {
    background-color: var(--blue-3) !important;
    color: var(--blue-2) !important;
  }

  &__link:active {
    background-color: var(--blue-1);
    color: var(--white);
  }
}
