.ak-tool-tip {
  background-color: var(--blue-2);
  font-size: 16px;
  color: var(--white);
  border-radius: 3px;
  padding: 2px 6px;
  &__arrow {
    color: var(--blue-2);
  }
}
