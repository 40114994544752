@import '../../../App/media';

.header {
  box-shadow: none;
  background-color: var(--white);

  &__bar {
    display: flex;
    justify-content: space-between;
    padding-left: var(--space-medium);
    padding-right: var(--space-medium);
    @include for-size(md) {
      padding-left: var(--space-medium-plus);
      padding-right: var(--space-medium-plus);
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
  }
  &__nav-icon {
    margin-right: var(--space-small);
    width: calc(var(--base-size) * 3);
    height: calc(var(--base-size) * 3);
    fill: var(--black);
  }

  &__logo {
    @include for-size($medium) {
      font-size: 20px;
    }
    color: var(--red-2);
    text-decoration: none;
    margin-left: var(--space-small);
    &:hover {
      text-decoration: underline;
      color: var(--blue-2);
    }
  }

  &__nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  &__nav-item {
    margin-left: var(--base-size);
  }

  &__nav-link {
    color: var(--black);
    text-decoration: none !important;
    display: flex;
    align-items: center;
  }
  &__button {
    height: calc(var(--base-size) * 6);
    width: calc(var(--base-size) * 6);
    svg {
      width: calc(var(--base-size) * 4);
      height: calc(var(--base-size) * 4);
      fill: var(--blue-2);
    }
    &--active {
      background-color: var(--blue-2);
      svg {
        fill: var(--white);
      }
    }
  }
}
