.datepicker-years {
  border-top: 1px solid var(--grey-1);
  padding-top: 10px;
  margin-top: 10px;
  &__year-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  &__year {
    height: 44px;
    width: calc(100% / 4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__year-btn {
    display: block;
    height: 36px;
    width: 64px;
    min-width: auto;
    border-radius: 100px;
    border: 0;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    outline: none;
    color: var(--black);
    &--curr-year {
      background-color: var(--white);
      border: 1px solid var(--grey-1);
    }
    &--selected-year {
      background-color: var(--blue-3);
      border: 1px solid var(--blue-3);
    }
    &:hover {
      background-color: var(--grey-1);
    }
  }
}
