.radio-group-field {
  position: relative;
  &__label {
    color: var(--black) !important;
    &--focused {
      color: var(--blue-1) !important;
    }
    &--error {
      color: var(--red-1) !important;
    }
    &--disabled {
      color: var(--grey-3) !important;
    }
  }
  &__item-label {
    &--disabled {
      cursor: not-allowed !important;
    }
  }
  &__radio-group {
    display: flex;
    flex-direction: row;
  }
  &__radio-btn {
    &--checked {
      color: var(--blue-1) !important;
    }

    &:hover {
      background-color: var(--grey-1) !important;
      color: var(--blue-1) !important;
    }
  }
  &__form-helper-text {
    position: absolute;
    left: 0;
    bottom: -10px;
    &--error {
      color: var(--red-1) !important;
    }
  }
}
