.select-tree {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__item {
    & > .select-tree__list {
      display: none;
    }
    &--is-open {
      & > .select-tree__list {
        display: block;
        margin-left: 48px;
      }
    }
  }

  &__icon {
    fill: var(--blue-1);
  }

  &__checkbox {
    border-radius: 3px;
    width: 36px;
    height: 36px;
    vertical-align: top;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }

  &__ripple {
    border-radius: 3px !important;
    background-color: transparent;
    &--visible {
      background-color: var(--blue-1);
      border-radius: 3px;
    }
  }
  &__ripple-child {
    background-color: var(--blue-1);
    border-radius: 3px;
  }

  &__item-btn {
    text-transform: none;
    color: var(--blue-1);
    text-align: left;
    font-size: 16px;
    border-radius: 3px;
    width: calc(100% - 48px - 48px);
    &:hover {
      color: var(--blue-2);
      background-color: transparent;
    }
    &--disabled {
      color: var(--black) !important;
      text-decoration: none;
    }
  }
  &__item-btn-label {
    justify-content: flex-start;
    line-height: 1.4;
    overflow: hidden;
    word-break: break-word;
  }

  &__icon-btn {
    border-radius: 3px;
    vertical-align: top;
    padding: 6px;
    &--no-children {
      opacity: 0;
    }
  }
}
