.business-division {
  &__modal {
    max-width: 650px;
    width: auto;
    min-width: 400px;
    .modal-header {
      min-height: 90px;
    }
    .modal-actions {
      padding-top: 10px;
    }
    .customized-dialog-content {
      overflow: auto !important;
      max-height: 300px;
    }
  }

  &__select-btn {
    color: var(--blue-1);
    text-transform: none;
    &--error {
      color: var(--red-1);
    }
  }
  &__label {
    &--error {
      color: var(--red-1);
    }
  }
  &__error-hint {
    color: var(--red-1);
    font-size: 16px;
  }
}
