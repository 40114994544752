.information-modal {
  .modal-actions {
    display: none;
  }
  .customized-dialog-content {
    padding-top: 5px;
    padding-bottom: 20px;
    min-height: auto;
  }
  .paper__width {
    min-height: auto;
    overflow: hidden;
  }
  &__footer {
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
}
