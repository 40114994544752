.icon-button {
  border-radius: 3px;
  margin: 0;
  display: flex;
  align-items: center;
  align-content: center;
  height: 44px;
  width: 44px;
  padding: 0;
  svg {
    width: 24px;
    height: 24px;
    fill: var(--blue-1);
  }

  &--contained {
    background-color: var(--blue-1);
    &:hover {
      color: var(--blue-2);
      background-color: var(--blue-4);
    }
  }
  &--outlined {
    color: var(--blue-1);
    border-color: var(--blue-3);
    &:hover {
      border-color: var(--blue-3);
      background-color: var(--blue-3);
      svg {
        fill: var(--blue-2);
      }
    }
  }
  &--active {
    background-color: var(--blue-3);
    svg {
      fill: var(--blue-2);
    }
  }

  &--disabled {
    color: var(--grey-3);
    svg {
      fill: var(--grey-3);
    }
    &:hover {
      svg {
        fill: var(--grey-3);
      }
    }
  }
}
