$extra-small: 'xs';
$small: 'sm';
$medium: 'md';
$large: 'lg';
$extra-large: 'xl';
$extra-extra-large: 'xxl';

@mixin for-size($size) {
  @if $size == $extra-extra-large {
    @media only screen and (min-width: 1920px) {
      @content;
    }
  } @else if $size == $extra-large {
    @media only screen and (min-width: 1440px) {
      @content;
    }
  } @else if $size == $large {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  } @else if $size == $medium {
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @else if $size == $small {
    @media only screen and (min-width: 576px) {
      @content;
    }
  } @else if $size == $extra-small {
    @media only screen and (max-width: 575px) {
      @content;
    }
  }
}
