.staff-profile-header {
  margin-bottom: 30px;
  &__controls--wrap {
    height: 70px;
    padding-top: 15px;
  }
  &__edit-btn-wrap {
    padding-left: 126px;
  }
  &__controls {
    display: flex;
    width: 100%;
    align-items: center;
  }
  &__file-name {
    color: var(--black);
  }
  &__drop-zone {
    min-width: 315px;
    margin-right: 20px;
  }
  &__image-wrap {
    width: 170px;
    display: flex;
    flex-direction: column;
  }
  &__edit-image-btn {
    &--active {
      background-color: var(--blue-3);
    }
  }
  &__edit-image-icon {
    &--active {
      fill: var(--blue-2) !important;
    }
  }
  &__delete-image-btn {
    margin: 0 15px;
  }
}
