.staff-catalog-panel {
  &__total-count {
    font-size: 24px;
    color: var(--blue-2);
    margin-bottom: 20px;
  }
  &__spinner {
    height: 100px;
    svg {
      width: 50px;
      height: 50px;
    }
  }
  &__observer {
    background-color: transparent;
    height: 1px;
  }
}
