.datepicker-panel {
  width: 320px;
  height: 363px;
  position: absolute;
  padding: 0 9px;
  left: 0;

  background-color: var(--white);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  z-index: 1;

  &--positioned-top {
    top: -370px;
  }
  &--positioned-bottom {
    top: 48px;
  }
}
