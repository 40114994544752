.organization {
  &__org-actions {
    margin-bottom: 24px;
    display: flex;
  }

  &__add-new-btn {
    background-color: var(--blue-1);
    &:hover {
      background-color: var(--blue-1);
    }
  }
  &__info-btn {
    margin-left: 8px;
  }
}
