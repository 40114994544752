@import 'media';

:root {
  // COLORS
  --blue-1: #357ca2;
  --blue-2: #172b53;
  --blue-3: #a3c3d4;
  --blue-4: #2c6685;
  --red-1: #c4401a;
  --red-2: #9f1d22;
  --green-1: #117b42;
  --green-2: #0e6838;
  --yellow-1: #efb71f;
  --yellow-2: #cb9b1a;
  --black: #000;
  --white: #fff;
  --grey-1: #e2e5e8; // line
  --grey-2: #67696a; // text
  --grey-3: #b0bac1; // disabled
  --primary-font: Verdana, Geneva, Tahoma, sans-serif;

  // BASE SIZE
  --base-size: 8px;

  // SPACING SIZE
  --space-extra-small: calc(var(--base-size) / 2); // 4px
  --space-small: var(--base-size); // 8px
  --space-small-plus: calc(var(--base-size) + var(--base-size) / 2); // 12px
  --space-medium: calc(var(--base-size) * 2); // 16px
  --space-medium-plus: calc(var(--base-size) * 3); // 24px
  --space-large: calc(var(--base-size) * 4); // 32px
  --space-large-plus: calc(var(--base-size) * 5); // 40px
  --space-extra-large: calc(var(--base-size) * 6); // 48px
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: var(--primary-font) !important;
}

body {
  font-size: calc(var(--base-size) * 2);
  line-height: calc(var(--base-size) * 3);
  color: var(--black);
}

a {
  color: var(--blue-1);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: var(--blue-2);
  }
}

.app-footer {
  height: 100px;
}

.app {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  padding-bottom: var(--space-medium-plus);

  // Extra small screen
  @include for-size(xs) {
    margin-left: var(--space-medium);
    margin-right: var(--space-medium);
  }

  // Small screen
  @include for-size(sm) {
    margin-left: var(--space-medium);
    margin-right: var(--space-medium);
  }

  // Medium screen
  @include for-size(md) {
    margin-left: var(--space-medium-plus);
    margin-right: var(--space-medium-plus);
  }

  // Large screen
  @include for-size(lg) {
    margin-left: var(--space-medium-plus);
    margin-right: var(--space-medium-plus);
  }

  // Extra large screen
  @include for-size(xl) {
    margin: 0 10%;
  }

  // Extra extra large screen
  @include for-size(xxl) {
    margin-left: auto;
    margin-right: auto;
  }
}
