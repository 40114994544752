.team-member-item-card {
  display: block;
  height: 100%;
  border-width: 0;

  &__title {
    font-size: 1.25rem;
    margin-bottom: 20px;
  }

  &__title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }

  &__content {
    margin-bottom: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.organization-team-member {
  margin-top: 50px;
}