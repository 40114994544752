.datepicker-dates {
  position: relative;
  &__dates-month {
    padding-left: 11px;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: capitalize;
    &--injected {
      position: absolute;
      top: 53px;
      left: 0;
    }
  }
  &__week-days {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: stretch;
    padding: 10px 0 5px;
    border-bottom: 1px solid var(--grey-1);
    margin-bottom: 5px;
  }
  &__week-day {
    color: var(--grey-2);
    text-align: center;
    text-transform: capitalize;
    flex: 1;
  }
  &__dates {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__date {
    width: calc(100% / 7);
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__date-btn {
    height: 42px;
    width: 42px;
    min-width: auto;
    min-height: auto;
    color: var(--black);
    padding: 0;
    display: block;
    border: 0;
    background-color: transparent;
    border-radius: 100%;
    outline: none;
    cursor: pointer;
    transition: none;
    &--chosen-date {
      background-color: var(--blue-3);
      border: 1px solid var(--blue-3);
      &:hover {
        background-color: var(--blue-3) !important;
        border: 1px solid var(--blue-3) !important;
      }
    }
    &--prev-date {
      opacity: 0;
    }
    &--curr-date {
      background-color: var(--white);
      border: 1px solid var(--grey-1);
    }
    &--next-date {
      opacity: 0;
    }
    &:hover {
      background-color: var(--grey-1);
      border: 1px solid var(--grey-1);
    }
  }
}
