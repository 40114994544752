.bread-crumbs {
  margin-top: 1.2rem;
  margin-bottom: 1rem;

  &__item {
    display: inline-flex;
    align-items: center;
  }
  &__link {
    font-size: 16px;
    color: var(--blue-1);
    text-align: left;
    &:hover {
      color: var(--blue-2);
    }
  }
  &__arrow {
    color: var(--blue-2);
    position: relative;
    top: 2px;
  }
}
