.select-field {
  $p: &;

  &__label {
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    #{$p} & {
      color: var(--grey-2);
    }

    &--focused {
      color: var(--blue-1) !important;
    }
    &--filled {
      color: var(--black);
    }
    &--error {
      color: var(--red-1) !important;
    }
    #{$p} &--shrinked {
      transform: scale(1);
      top: 3px;
      color: var(--black);
    }
    &--disabled {
      color: var(--grey-3) !important;
    }
  }
  &__underline {
    &:hover {
      &::before {
        border-color: var(--blue-1) !important;
      }
    }
    &::before {
      border-bottom: 1px solid var(--blue-1) !important;
    }
    &::after {
      border-color: var(--blue-1) !important  ;
    }
    &:hover::before {
      border-bottom: 2px solid var(--blue-1);
    }

    &--disabled {
      &:before {
        border-bottom: 1px dotted var(--grey-3) !important;
      }
      &:after {
        border-color: var(--grey-3);
      }
      &:hover {
        &:before {
          border-bottom: 1px dotted var(--grey-3) !important;
        }
      }
    }

    &--error {
      &::before {
        border-bottom: 1px solid var(--red-1) !important;
      }
      &::after {
        border-color: var(--red-1) !important;
      }
      &:hover::before {
        border-bottom: 1px solid var(--red-1) !important;
      }
    }
  }
  &__select {
    &--disabled {
      cursor: not-allowed !important;
      &:before {
        border-bottom-style: solid !important;
      }
    }
  }
  &__paper {
    border-radius: 3px;
    background: var(--white);
  }
  &__list {
    padding: 0;
  }
  &__menu-item {
    padding: 16px 12px;
    font-size: 16px;
    line-height: 24px;
    border: 0;
    background-color: var(--white) !important;
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-1);
    }
    &:hover {
      color: var(--blue-2);
      background-color: var(--blue-3) !important;
    }
    &--selected {
      background-color: var(--blue-3) !important;
      color: var(--black) !important;
    }
  }
  &__helper {
    font-size: 16px;
    &--error {
      color: var(--red-1) !important;
    }
  }
}
