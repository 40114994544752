.employee-card {
  $marginBottomStep: 12px;
  display: flex;
  width: 50%;
  border-bottom: 1px solid var(--grey-1);
  margin-bottom: 25px;
  padding-bottom: 25px;
  @media (max-width: 880px) {
    width: 100%;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &--outlined {
    border: 1px solid var(--grey-1);
    border-radius: 3px;
    padding-left: 25px;
    padding-top: 25px;
  }
  &__preview {
    display: block;
    position: relative;
    overflow: hidden;
    width: 173px;
    height: 230px;
    min-width: 173px;
  }
  &__preview-icon {
    position: absolute;
    width: 200px;
    height: auto;
    top: 0px;
    left: -18px;
    fill: var(--grey-3);
  }
  &__preview-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    &:hover {
      opacity: 0.8;
    }
  }
  &__info {
    list-style: none;
    margin: 0;
    padding: 0 15px;
    min-width: 0;
  }
  &__name {
    font-size: 20px;
    margin-bottom: #{$marginBottomStep};
  }
  &__name-link,
  &__email-link,
  &__phone-link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__title {
    text-transform: lowercase;
    margin-bottom: 2px;
  }
  &__businessDivision {
    margin-bottom: #{$marginBottomStep};
    text-transform: lowercase;
  }
  &__companyCity {
    margin-bottom: #{$marginBottomStep};
    text-transform: capitalize;
  }
  &__email {
    margin-bottom: #{$marginBottomStep};
  }
  &__email-link {
    display: block;
    word-wrap: break-word;
  }
  &__phone {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
