.text-field {
  $p: &;

  &--search-field {
    display: flex;
    width: 100%;
  }

  &__label {
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    #{$p} & {
      color: var(--grey-2);
    }

    &--focused {
      color: var(--blue-1) !important;
    }
    &--filled {
      color: var(--black);
    }
    &--error {
      color: var(--red-1) !important;
    }
    #{$p} &--shrinked {
      transform: scale(1);
      top: 3px;
      color: var(--black);
    }
    &--disabled {
      color: var(--grey-3) !important;
    }
  }
  &__underline {
    &:hover {
      &::before {
        border-color: var(--blue-1) !important;
      }
    }
    &::before {
      border-bottom: 1px solid var(--blue-1);
    }
    &::after {
      border-color: var(--blue-1) !important  ;
    }
    &:hover::before {
      border-bottom: 2px solid var(--blue-1);
    }

    &--disabled {
      input {
        cursor: not-allowed !important;
      }
      &::before {
        border-bottom: 1px dotted var(--grey-3) !important;
      }
      &::after {
        border-color: var(--grey-3);
      }
      &:hover::before {
        border-bottom: 1px dotted var(--grey-3) !important;
      }
    }

    &--error {
      &::before {
        border-bottom: 1px solid var(--red-1) !important;
      }
      &::after {
        border-color: var(--red-1) !important;
      }
      &:hover::before {
        border-bottom: 1px solid var(--red-1) !important;
      }
    }
  }

  &__helper {
    font-size: 16px;
    &--error {
      color: var(--red-1) !important;
    }
  }

  &__search-clear-btn {
    padding: 2px;
    top: -9px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
