.datepicker-controls {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  &__btn {
    height: 44px;
    font-size: 16px;
    min-width: 44px;
    padding: 0;
    cursor: pointer;
    border-radius: 3px;
    background-color: transparent;
    border: 0;
    margin-left: 2px;
    outline: none;
    &--date-display {
      min-width: auto;
      padding: 0 12px;
      margin-right: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--black);
      text-transform: capitalize;
      &:hover {
        background-color: #f8f8f8 !important;
      }
    }
    &:hover {
      background-color: #a3c4d4;
    }
  }
  &__icon {
    width: 24px;
    height: 24px;
  }
  &__expansion-icon {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    transition: 0.2s;
  }
}
