.datepicker-input {
  $p: &;
  width: 100%;

  &__btn {
    height: 24px;
    width: 24px;
    min-width: initial !important;
    min-height: initial !important;
    padding: 0 !important;
    border: none !important;

    &--active {
      background-color: var(--grey-1);
      border: 2px solid var(--blue-1);
    }
    &--focused {
      border: 2px solid var(--blue-1);
    }
    &:hover {
      background-color: var(--grey-1);
    }
  }
  &__icon {
    width: 16px;
    height: 16px;
    fill: var(--blue-1);
    &--focused {
      fill: var(--blue-2);
    }
    &--error {
      fill: var(--red-1);
    }
    &--disabled {
      fill: var(--grey-3);
    }
  }
  &__label {
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    #{$p} & {
      color: var(--grey-2);
    }

    &--focused {
      color: var(--blue-1) !important;
    }
    &--filled {
      color: var(--black);
    }
    &--error {
      color: var(--red-1) !important;
    }
    #{$p} &--shrinked {
      transform: scale(1);
      top: 3px;
      color: var(--black);
    }
  }
  &__underline {
    &:hover {
      &::before {
        border-color: var(--blue-1) !important;
      }
    }
    &::before {
      border-bottom: 1px solid var(--blue-1);
    }
    &::after {
      border-color: var(--blue-1) !important  ;
    }
    &:hover::before {
      border-bottom: 2px solid var(--blue-1);
    }

    &--disabled {
      input {
        cursor: not-allowed !important;
      }
      &::before {
        border-bottom: 1px solid var(--grey-3);
      }
      &::after {
        border-color: var(--grey-3);
      }
      &:hover::before {
        border-bottom: 1px solid var(--grey-3) !important;
      }
    }

    &--error {
      &::before {
        border-bottom: 1px solid var(--red-1) !important;
      }
      &::after {
        border-color: var(--red-1) !important;
      }
      &:hover::before {
        border-bottom: 1px solid var(--red-1) !important;
      }
    }
  }
  &__helper-text {
    font-size: 16px;
    &--error {
      color: var(--red-1) !important;
    }
  }
}
