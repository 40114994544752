.staff-catalog-filters {
  margin-bottom: 16px;
  &__search {
    display: flex;
    margin-bottom: 16px;
  }
  &__business-division {
    display: flex;
    align-items: flex-start;
  }
  &__filter-control-btn {
    &--active {
      background-color: var(--blue-3);
      &:hover {
        background-color: var(--blue-3);
      }
    }
  }
  &__filter-control-icn {
    &--active {
      fill: var(--blue-2) !important;
    }
  }
  &__filters-block {
    margin-bottom: 32px;
  }
  &__filter-criteria {
    margin-bottom: 16px;
  }
  &__filters-clear {
    margin-bottom: 5px;
  }
}
