.snackbar {
  &__content {
    background: #fff;
    color: var(--black);
    box-shadow: none;
    margin: 5px;
    padding: 16px;
  }

  &__content--error {
    border: 1px solid var(--red-1);
  }

  &__content--success {
    border: 1px solid var(--green-1);
  }

  &__icon {
    color: var(--white);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__icon--error {
    fill: var(--red-1);
  }

  &__icon--success {
    fill: var(--green-1);
  }

  &__message {
    display: flex;
    margin-right: 45px;
  }

  &__button {
    position: absolute;
    top: 19px;
    right: 11px;
  }

  &__close-icon {
    fill: var(--blue-1);
  }
}
